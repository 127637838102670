import { HouseholdComponent } from './household.component';
import Vs from '@app/services/validators/validators.service';
import { isNil, or, evolve } from 'ramda';
import { trudy } from '@app/helpers/function.helpers';
import { lookIn } from '@app/helpers/flipped.helpers';
import { disableGet } from '@app/helpers/able.helpers';
import { isAssistingPerson, notOther } from '@app/helpers/models.helpers';
import { controlWith } from '@app/helpers/form.helpers';
import { nilControl } from '@app/helpers/form-control.helpers';
import { isSelf, sectionConfiguration } from '@app/helpers/models.helpers';
import { nhAddressCreator, nameCreator, phonesCreator } from '@app/helpers/models.helpers';
import { toDate, toISO } from '@app/helpers/date.helpers';
import { isNo, isYes } from '@app/helpers/tayn.helpers';
import labels from './household-labels';
import { isChangeReport, isNewReapplyMode, isRedeMode} from '@app/helpers/mode.helpers';


const formValidation = (builder, recordData) => {
  const household = lookIn(recordData);
  const ackSignSw = household(['assistingPerson','ackSignSw']);
  const assistingPersonSignName = household(['assistingPerson','assistingPersonSignName']);
  const applicantAckSignSw = household(['assistingPerson','applicantAckSignSw']);
  const applicantSignName = household(['assistingPerson','applicantSignName']);
  const primaryLanguage = household(['languagePreference', 'primaryLanguage']);
  const applicationSource = household(['physicalAddress', 'applicationSource']);

  //MP   FIRST - assistingOnBehalf  SECOND: addAssistingPersonToApp
  const assistingOnBehalfCtrl = household(['assistingOnBehalf']);
  const addToApplication = household(['addAssistingPersonToApp']);

  // FRM Old
  // "ppAddAssistingPersonToApp - Yes/No"
  // "ppAssistingOnBehalf - Assis/Self"

  // FRM New
  // "ppAddAssistingPersonToApp - Yes/No"
  // "ppAssistingOnBehalf - Yes/No"

  // PP FC
  // "ppAddAssistingPersonToApp - Yes/No"
  // "ppAssistingOnBehalf - Yes/No"
  const addAssistingPersonToAppPPFirstCtrl = household(['ppAddAssistingPersonToApp']);
  const assistingOnBehalfPPSecond = household(['ppAssistingOnBehalf']);

  const homeless =  household(['homeless']);
  const sahPrgSw = household(['sahPrgSw']);
  const sahMailId = household(['sahMailId']);
  const sahCountyCd = household(['sahCountyCd']);
  const applicationDt = household(['applicationDt']);
  const helpDuration = household(['assistingPerson', 'helpDuration']);
  const relationship = household(['assistingPerson', 'relationship']);
  const email = household(['assistingPerson', 'email']);
  const organizationName = household(['assistingPerson', 'organizationName']);
  const idNumber = household(['assistingPerson', 'idNumber']);
  const bestTime = household(['assistingPerson', 'bestTime']);
  const addressFormatP = household(['physicalAddress', 'addressFormat']);
  const addressFormatA = household(['assistingPerson', 'addressFormat'])
  const applicantBehalf = household(['assistingPerson', 'responsibilities', 'applicantBehalf']);
  const submitRenewal = household(['assistingPerson', 'responsibilities', 'submitRenewal']);
  const letterOtherCommunication = household(['assistingPerson', 'responsibilities', 'letterOtherCommunication']);
  const yourBehalf = household(['assistingPerson', 'responsibilities', 'yourBehalf']);
  const isFinReassessment = JSON.parse(sessionStorage.getItem('isReassessment'));

  const appType = sessionStorage.getItem('appType');
  const isKepro = JSON.parse(sessionStorage.getItem('isKepro'));
  const user = sessionStorage.getItem('userType');
  const isMedicAidApp = (user === 'RP' &&  appType === 'AFB' && !isKepro);
  const mode = sessionStorage.getItem('mode');
  //const isRenew = sessionStorage.getItem('isRenew');
  //const ppTccHouseHoldControlNotReq: boolean = ((appType === 'RC' || appType === 'RB') && user === 'RP' && isRenew?.toUpperCase() === 'YES');

  const mailingAddressFormat = household(['mailingAddress', 'addressFormat']);
  const mailAddress = household(['mailingAddress', 'address']);
  if (mailingAddressFormat?.code == 'MI') {
    const tempAeAP = mailAddress.addrAaAeAp;
    tempAeAP['description'] = tempAeAP.value;
    tempAeAP['spDescription'] = tempAeAP.spValue;
    delete tempAeAP.spValue;

    const tempApFp = mailAddress.addrApoFpo;
    tempApFp['description'] = tempApFp.value;
    tempApFp['spDescription'] = tempApFp.spValue;
    delete tempApFp.spValue;

    mailAddress.addrAaAeAp = tempAeAP;
    mailAddress.addrApoFpo = tempApFp;
  }
  const helpers = builder.group({
    addMailingInfo: ['', Vs.required],
    errorMessage : false,
  });


  //For AHS AFB, RMB, RMC
  const  isAhsRmbOrRmc = (isKepro && user === 'RP') && 
  ( appType === 'REDETERMINATION' || appType === 'RB' ||  appType === 'CHANGE_REPORT' || appType === 'RC' || appType === 'AFB') && 
          (isRedeMode(mode) || isChangeReport(mode) || isNewReapplyMode(mode));
          
  // const testingForPPAFB =  and(user === 'RP', appType === 'AFB');
  // const testingForPPFRM =  and(user === 'RP', isFinReassessment);
  const viewTCC = JSON.parse(sessionStorage.getItem('ViewTCC'));
  const ppViewTccHoHrmbrmc = ((appType === 'RC' || appType === 'RB') && user === 'RP' && viewTCC && !isFinReassessment);
  const testingForMP =  user !== 'RP' || isAhsRmbOrRmc || ppViewTccHoHrmbrmc ;


  const assistingBehalf = () => {
    if (isMedicAidApp && relationship != null) {
      return { code: 'Y', value: 'Yes', spValue: 'Si' }
    } else if ((isMedicAidApp && isNil(relationship) && primaryLanguage != null)) {
      return { code: 'N', value: 'No', spValue: 'No', }
    } else if (!isMedicAidApp) {
      return assistingOnBehalfCtrl
    } else {
      return null;
    }
  };


  const addAssistingPersonToAppPPFirstFC = () => {
    if (user === 'RP' && (isFinReassessment || appType === 'AFB') && !isKepro) {
      return addAssistingPersonToAppPPFirstCtrl;
    } else {
      return null;
    }
  };
  

  // const nilAssitOrIsSelf = isMedicAidApp ? or(isNil(addAssistingPersonToAppPPFirstCtrl), (isNo(addAssistingPersonToAppPPFirstCtrl) && (isNil(assistingOnBehalfPPSecond) || isNo(assistingOnBehalfPPSecond)))) :
  //                         testingForMP ? or(isNil(assistingOnBehalfCtrl), (isSelf(assistingOnBehalfCtrl) && (isNil(addToApplication) || isNo(addToApplication)))) :
  //                         or(isNil(addAssistingPersonToAppPPFirstCtrl), (isNo(addAssistingPersonToAppPPFirstCtrl) && (isNil(assistingOnBehalfPPSecond) || isSelf(assistingOnBehalfPPSecond))));

  const nilAssitOrIsSelf = testingForMP ? or(isNil(assistingOnBehalfCtrl), (isSelf(assistingOnBehalfCtrl) && (isNil(addToApplication) || isNo(addToApplication)))) :
                          or(isNil(addAssistingPersonToAppPPFirstCtrl), (isNo(addAssistingPersonToAppPPFirstCtrl) && (isNil(assistingOnBehalfPPSecond) || isNo(assistingOnBehalfPPSecond))));

  const data = builder.group({
    dateOfApplication: [toDate(household(['dateOfApplication'])), [Vs.required]],
    healthPlan: [null],
    casehead: builder.group(nameCreator(household(['casehead']))),
    languagePreference: builder.group({
      primaryLanguage: [primaryLanguage ? primaryLanguage : { code: '0', value: 'English' }],
      otherLanguageName: [{
          value: household(['languagePreference', 'otherLanguageName']),
          disabled: notOther(primaryLanguage),
        }, [Vs.required, Vs.maxLength(50), Vs.language]],
      letterLanguage: household(['languagePreference', 'letterLanguage'], [Vs.required]),
    }),
    applicationDt: [toDate(applicationDt), Vs.required],
    homeless: [homeless, Vs.required],
    sahPrgSw: [sahPrgSw],
    sahMailId: [sahMailId, [Vs.safeAtHomeMailID, Vs.maxLength(50)]],
    sahCountyCd: [sahCountyCd],
    assistingPersonFRM:[null],
    physicalAddress: builder.group({
      addressFormat: [nilControl(addressFormatP), Vs.required],
      address: builder.group(nhAddressCreator(household(['physicalAddress', 'address']))),
      contactPhones: isYes(homeless) ? builder.group(phonesCreator(household(['mailingAddress', 'contactPhones']))) : builder.group(phonesCreator(household(['physicalAddress', 'contactPhones']))),
      applicationSource: [controlWith(applicationSource, !isKepro), [Vs.required]],
      bestTime: household(['physicalAddress', 'bestTime']),
    }),
    mailingAddress: builder.group({
      addressFormat: [household(['mailingAddress', 'addressFormat']), Vs.required],
      address: builder.group(nhAddressCreator(mailAddress)),
    }),
    assistingOnBehalf: [assistingBehalf(), Vs.required],
    addAssistingPersonToApp: [controlWith(household(['addAssistingPersonToApp']), (isNil(assistingOnBehalfCtrl) || isAssistingPerson(assistingOnBehalfCtrl))), Vs.required],

    ppAddAssistingPersonToApp: [addAssistingPersonToAppPPFirstFC(), Vs.required],
    // ppAssistingOnBehalf: testingForPPFRM? [controlWith(household(['ppAssistingOnBehalf']),(isNil(addAssistingPersonToAppPPFirstCtrl) || isYes(addAssistingPersonToAppPPFirstCtrl))), Vs.required]: 
    //                                           [controlWith(household(['ppAssistingOnBehalf']),(isNil(addAssistingPersonToAppPPFirstCtrl) || isYes(addAssistingPersonToAppPPFirstCtrl))), Vs.required],
    ppAssistingOnBehalf: [controlWith(household(['ppAssistingOnBehalf']),(isNil(addAssistingPersonToAppPPFirstCtrl) || isYes(addAssistingPersonToAppPPFirstCtrl))), Vs.required],

    assistingPerson: builder.group({
      ackSignSw: [ackSignSw],
      assistingPersonSignName: [(assistingPersonSignName), [Vs.signatureName]],
      applicantAckSignSw: [applicantAckSignSw, Vs.required],
      applicantSignName: [(applicantSignName), [Vs.required, Vs.signatureName]],

      // relationship: testingForMP ? [controlWith(relationship, (isSelf(assistingOnBehalfCtrl) || isNil(assistingOnBehalfCtrl))),  Vs.required] : 
      //               testingForPPAFB ? [controlWith(relationship, (isNo(addAssistingPersonToAppPPFirstCtrl) || isNil(addAssistingPersonToAppPPFirstCtrl))),  Vs.required] :
      //               [controlWith(relationship, (isNo(addAssistingPersonToAppPPFirstCtrl) || isNil(addAssistingPersonToAppPPFirstCtrl))),  Vs.required],

      relationship: testingForMP ? [controlWith(relationship, (isSelf(assistingOnBehalfCtrl) || isNil(assistingOnBehalfCtrl))),  Vs.required] : 
                   [controlWith(relationship, (isNo(addAssistingPersonToAppPPFirstCtrl) || isNil(addAssistingPersonToAppPPFirstCtrl))),  Vs.required],          


      helpDuration: [controlWith(helpDuration, !helpDuration)],
      name: builder.group(nameCreator(household(['assistingPerson', 'name']))),
      organizationName: [controlWith(organizationName, nilAssitOrIsSelf), Vs.maxLength(256)],
      idNumber: [controlWith(idNumber, nilAssitOrIsSelf), Vs.maxLength(20)],
      addressFormat: [controlWith(addressFormatA, !addressFormatA), Vs.required],
      address: builder.group(nhAddressCreator(household(['assistingPerson', 'address']))),
      email: [controlWith(email, !email), [Vs.email]],
      bestTime: [controlWith(bestTime, nilAssitOrIsSelf)],
      contactPhones: builder.group(phonesCreator(household(['assistingPerson', 'contactPhones']))),
      responsibilities: builder.group({
        applicantBehalf: [controlWith(applicantBehalf, !applicantBehalf)],
        submitRenewal: [controlWith(submitRenewal, !submitRenewal)],
        letterOtherCommunication: [controlWith(letterOtherCommunication, !letterOtherCommunication)],
        yourBehalf: [controlWith(yourBehalf, !yourBehalf)]
      })
    }),
    hiddenField: null,
  })

  console.log(data)
  trudy(() => disableGet('physicalAddress.address', data), isYes(homeless));
  trudy(() => [
    disableGet('assistingPerson.name', data),
    disableGet('assistingPerson.address', data),
    disableGet('assistingPerson.contactPhones', data)
  ], nilAssitOrIsSelf);

  return { helpers, data };
};

const postObject = evolve({
  dateOfApplication: toISO,
  applicationDt: toISO,
});

export default sectionConfiguration(
  HouseholdComponent,
  formValidation,
  ['PRIMARYLANGUAGE', 'WRITTENLANGUAGE', 'MCONAME', 'MCONAME_UBW', 'YESNO', 'ADDRESSFORMATTYPES', 'MILITARYPOCODES',
  'APRNSOURCE', 'CONTACTTIME', 'MPASSTPER', 'AUTHREPTIME', 'AUTHREPRELATIONSHIP', 'NAMESUFFIX', 'STATE', 'COUNTY', 'STATEMILITARY', 'MCONAME'],
  [],
  {...{}, ...labels},
  {
    postObject: postObject,
    hasSummary: false,
    validateSubmit: true,
  }
);